import { createRoot } from 'react-dom/client';

import App from './App';

const root = document.getElementById('root');

if (!root) {
  throw new Error('Não foi possível localizar o elemento');
}

createRoot(root).render(<App />);